import React, { useEffect, useLayoutEffect } from "react";
import $ from "jquery";
import { twoDecimals } from "../../../Utils/twoDecimals";
import { t } from "i18next";
import StudentProfileName from "../../../Components/StudentProfileName";
import LessonHeader from "../../../Components/LessonHeader";
import ActivityTestAttemptInfo from "../../../Components/ActivityTestAttemptInfo";
import ActivityTestRubrics from "../../../Components/ActivityTestRubrics";

export const LessonTeacherSummaryNote = (props) => {
  const {
    slidePosition,
    globalState,
    rubrics,
    deadLine,
    mode,
    exercises,
    apiInfo,
    corrections,
    finalCorrection,
  } = props;

  useEffect(() => {
    refreshScore();
  }, [corrections]);

  useLayoutEffect(() => {
    let competencies = [];

    for (let competence of finalCorrection.competencies) {
      let competency = competence;

      if (Number(finalCorrection.score) < 4) {
        competency.level = 1;
      } else if (
        Number(finalCorrection.score) < 6 &&
        Number(finalCorrection.score) >= 4
      ) {
        competency.level = 2;
      } else if (
        Number(finalCorrection.score) < 8 &&
        Number(finalCorrection.score) >= 6
      ) {
        competency.level = 3;
      } else if (Number(finalCorrection.score) >= 8) {
        competency.level = 4;
      }

      competencies.push(competency);
    }

    let currentFinalCorrection = {
      ...finalCorrection,
      competencies,
    };

    globalState.setFinalCorrection(currentFinalCorrection);
  }, [finalCorrection.score]);

  function refreshScore() {
    let score = 0;
    for (let correction of corrections) {
      let correctionScore = parseFloat(correction.score);
      score += correctionScore;
    }
    score /= corrections.length;

    let currentFinalCorrection = {
      ...finalCorrection,
      score: twoDecimals(score * 100) / 100,
    };

    globalState.setFinalCorrection(currentFinalCorrection);
  }

  function refreshCorrection() {
    let feedbackValue = $(`.slideNote${slidePosition} .slideNoteTeacherComments textarea[name="feedback"]`).val();

    let currentFinalCorrection = {
      ...finalCorrection,
      feedback: feedbackValue
    };

    globalState.setFinalCorrection(currentFinalCorrection);
  }

  function refreshRubrics() {
    let competencies = [];

    for (let competence of finalCorrection.competencies) {
      let competency = competence;

      $(`.slideNote${slidePosition} .rubric.${competence.competency} input[type="radio"]`).each(function (index) {
        if ($(this)[0].checked) {
          competency.level = index + 1;
        }
      });

      competencies.push(competency);
    }

    if (!finalCorrection.competencies) {
      $(`.slideNote${slidePosition}  input[type="radio"]`).each(function (index) {
        if ($(this)[0].checked) {
          competencies.push({ level: index + 1 });
        }
      });
    }

    let currentFinalCorrection = {
      ...finalCorrection,
      competencies
    };

    globalState.setFinalCorrection(currentFinalCorrection);
  }

  let showTeacherComments =
    globalState.role === "teacher" && globalState.mode.includes("in_review");

  return (
    <div className={`slideNote ${globalState.role} slideNote${slidePosition} summaryNote`}
      id="summaryNote" data-position={slidePosition}>

      <div className={`slideNoteWrapper ${ showTeacherComments ? "additionalPadding" : "" }`}>

        {globalState.apiInfo.student && globalState.role !== "student" ? (
            <StudentProfileName
                studentProfile={globalState.apiInfo.student}
                mode='inline'
            />
        ) : null}

        <div className="slideNoteContent">
          <div className="container">

            <LessonHeader globalState={globalState} deadLine={deadLine} mode={mode} apiInfo={apiInfo}
                          rubrics={rubrics} lessonName={globalState.functions.getName()} />

            <div className="row">
              <div className="col-12">

                <ActivityTestAttemptInfo exercises={exercises} globalState={globalState} results={finalCorrection}
                                         corrections={corrections} showBadge={true} title={t("average")} />

              </div>
            </div>

            <ActivityTestRubrics rubrics={rubrics} mode={mode} results={finalCorrection} refreshRubrics={refreshRubrics} />

          </div>
        </div>
      </div>

      {showTeacherComments ? (
        <div className="slideNoteTeacherComments asTeacher">
          <div className="additionalComments">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.25 15.9102C2.38805 15.9102 1.5614 15.5677 0.951903 14.9583C0.34241 14.3488 0 13.5221 0 12.6602V4.16016C0 3.2982 0.34241 2.47155 0.951903 1.86206C1.5614 1.25257 2.38805 0.910156 3.25 0.910156H16.75C17.1768 0.910156 17.5994 0.99422 17.9937 1.15755C18.388 1.32088 18.7463 1.56027 19.0481 1.86206C19.3499 2.16385 19.5893 2.52213 19.7526 2.91644C19.9159 3.31074 20 3.73336 20 4.16016V12.6602C20 13.087 19.9159 13.5096 19.7526 13.9039C19.5893 14.2982 19.3499 14.6565 19.0481 14.9583C18.7463 15.26 18.388 15.4994 17.9937 15.6628C17.5994 15.8261 17.1768 15.9102 16.75 15.9102H11.012L6 19.6602C5.81428 19.7992 5.59356 19.8837 5.36251 19.9044C5.13146 19.9251 4.89922 19.8811 4.69176 19.7773C4.4843 19.6735 4.30981 19.5141 4.18782 19.3168C4.06582 19.1195 4.00114 18.8921 4.001 18.6602V15.9102H3.251H3.25ZM10.514 14.4102H16.75C17.2141 14.4102 17.6592 14.2258 17.9874 13.8976C18.3156 13.5694 18.5 13.1243 18.5 12.6602V4.16016C18.5 3.69603 18.3156 3.25091 17.9874 2.92272C17.6592 2.59453 17.2141 2.41016 16.75 2.41016H3.25C2.78587 2.41016 2.34075 2.59453 2.01256 2.92272C1.68437 3.25091 1.5 3.69603 1.5 4.16016V12.6602C1.5 13.6262 2.284 14.4102 3.25 14.4102H5.499V18.1602L10.514 14.4102Z"
                fill="#374047"
              />
            </svg>
            <span>{t("additional.comments")}</span>
          </div>
          <div className="commentsTextArea">
            <textarea
              name={`feedback`}
              onChange={refreshCorrection}
              value={finalCorrection.feedback}></textarea>
          </div>
        </div>
      ) : null}
    </div>
  );
};
